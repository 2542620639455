import React, { useEffect, useState } from 'react'
import { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useInterval } from 'ahooks'
import { Toast } from 'antd-mobile'
import { stringify } from 'qs'
import Div100vh from 'react-div-100vh'

import { useAppSelector } from '@/store/hooks'
import { selectGlobal } from '@/store/global'
import { storagePrefix } from '@/utils'

import icCheckboxImg from '@/pagesFolder/login/images/ic_checkbox.png'
import icFeedbackImg from '@/pagesFolder/login/images/ic_feedback.png'
import icGoogleImg from '@/pagesFolder/login/images/ic_google.png'
import images2 from '@/pagesFolder/login/images/2.jpg'
import images3 from '@/pagesFolder/login/images/3.jpg'
import images4 from '@/pagesFolder/login/images/4.jpg'
import images5 from '@/pagesFolder/login/images/5.jpg'
import images6 from '@/pagesFolder/login/images/6.jpg'
import images7 from '@/pagesFolder/login/images/7.jpg'
import images8 from '@/pagesFolder/login/images/8.jpg'
import images9 from '@/pagesFolder/login/images/9.jpg'
import images10 from '@/pagesFolder/login/images/10.jpg'
import styles from './index.module.scss'

const i18ns = 'login'
const bgMap = {
  1: images7.src,
  2: images2.src,
  3: images3.src,
  4: images4.src,
  5: images5.src,
  6: images6.src,
  7: images7.src,
  8: images8.src,
  9: images9.src,
  10: images10.src
}

const generateNum = () => Math.floor(Math.random() * 10 + 1)

const Login: NextPage = () => {
  const { t, lang } = useTranslation(i18ns)
  const router = useRouter()
  const { isLogin } = useAppSelector(selectGlobal)
  const [selected, setSelected] = useState(false)
  const [bgNum, setBgNum] = useState(generateNum())

  useEffect(() => {
    setSelected(localStorage[storagePrefix('hasLogin')] === '1' ? true : false)

    if (isLogin) {
      router.push('/user/home')
    }
  }, [])

  useInterval(() => {
    setBgNum(generateNum())
  }, 10000)

  const googleLogin = () => {
    if (!selected) {
      Toast.show({
        content: t('请先勾选「我已阅读并同意使用协议」'),
        duration: 3000
      })
      return
    }

    const query = stringify({
      scope: 'email openid profile',
      client_id: '634379028402-omfberhgi4rienl1n9gnmbqlkec050os.apps.googleusercontent.com',
      redirect_uri: `${window.location.origin}/auth/google`,
      response_type: 'code'
    })

    window.location.assign(`https://accounts.google.com/o/oauth2/v2/auth?${query}`)
  }

  const toogleSelected = () => {
    setSelected(val => !val)
  }

  const gotoFeedback = () => {
    window.open('https://forms.gle/K7xwC9egQtyGQRAc9')
  }

  const gotoAgreement = () => {
    // router.push('/login/agreement?needLogin=false')
    window.open('https://intercom.help/Snaptube-Help-Center/pt/articles/6883563-framote-1')
  }

  const gotoPrivacyPolicy = () => {
    // router.push('/login/privacy-policy?needLogin=false')
    window.open('https://intercom.help/Snaptube-Help-Center/pt/articles/6883567-framote-2')
  }

  const agreementText = () => {
    const replaceTextMap = {
      en: 'Use Agreement',
      pt: 'Termos de Uso'
    }
    const replaceTextMap1 = {
      en: 'Privacy Policy',
      pt: 'Políticas de Privacidade'
    }

    // @ts-ignore
    const replaceText = replaceTextMap[lang]
    // @ts-ignore
    const replaceText1 = replaceTextMap1[lang]

    const strTitle = t('我已阅读并同意使用协议和隐私政策')
    const index = strTitle.toLowerCase().indexOf(replaceText.toLowerCase())
    const beforeStr = strTitle.substring(0, index)
    const indexStr = strTitle.substring(index, index + replaceText.length)
    const afterStr = strTitle.slice(index + replaceText.length)

    const index1 = afterStr.toLowerCase().indexOf(replaceText1.toLowerCase())
    const beforeStr1 = afterStr.substring(0, index1)
    const indexStr1 = afterStr.substring(index1, index1 + replaceText1.length)
    const afterStr1 = afterStr.slice(index1 + replaceText1.length)

    const title =
      index > -1 ? (
        <>
          {beforeStr}
          <span className={styles.link} onClick={gotoAgreement}>
            {indexStr}
          </span>
          {index1 > -1 ? (
            <>
              {beforeStr1}
              <span className={styles.link} onClick={gotoPrivacyPolicy}>
                {indexStr1}
              </span>
              {afterStr1}
            </>
          ) : (
            <>{afterStr}</>
          )}
        </>
      ) : (
        <>{strTitle}</>
      )

    return title
  }

  return (
    <div>
      <Head>
        <title>{t('Framote')}</title>
      </Head>
      <div className={styles.login}>
        <Div100vh>
          <div
            className={styles.wrapper}
            style={{
              // @ts-ignore
              backgroundImage: `url(${bgMap[bgNum]})`
            }}
          >
            <img className={styles.feedback} src={icFeedbackImg.src} alt="" onClick={gotoFeedback} />
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.title}>{t('Framote')}</div>
                <div className={styles.subTitle}>{t('营销中心')}</div>
                <div className={styles.desc}>{t('在这里让你的影响力变现！')}</div>
              </div>
              <div className={styles.box}>
                <div className={styles.login} onClick={googleLogin}>
                  <img className={styles.icon} src={icGoogleImg.src} alt="" />
                  <div className={styles.text}>{t('用Google登录')}</div>
                </div>
                <div className={styles.agreement}>
                  {selected ? (
                    <img className={styles.checkboxSelected} src={icCheckboxImg.src} alt="" onClick={toogleSelected} />
                  ) : (
                    <div className={styles.checkbox} onClick={toogleSelected} />
                  )}
                  <div className={styles.text}>{agreementText()}</div>
                </div>
              </div>
            </div>
          </div>
        </Div100vh>
      </div>
    </div>
  )
}

Login.getInitialProps = () => {
  return { ns: i18ns }
}

export default Login
